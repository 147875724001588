<template>
  <div class="card p-10">
    <div>
      <h2 class="text-blue fw-bolder">Agregar área</h2>
    </div>
    <form class="row my-5 pb-5" @submit.prevent="submit">
      <div class="col-12 my-5">
        <label class="text-secondary" for="">Ubicación</label>
        <select
          class="form-select bg-gray-input text-gray border-0 rounded-0"
          aria-label="Default select example"
          v-model="ubicacionId"
          name="ubicacionId"
          id="ubicacionId"
        >
          <option disabled value="">Seleccione</option>
          <option
            v-for="(ubicacion, i) in ubicaciones"
            :key="i"
            :value="ubicacion._id"
          >
            {{ ubicacion.nombre }}
          </option>
        </select>
        <div class="col-12 my-5">
          <label class="text-secondary" for="">Nombre del área</label>
          <input
            type="text"
            class="form-control border-0 rounded-0 bg-gray-input"
            v-model="model.nombre"
            id="nombre"
          />
        </div>
        <div class="fv-plugins-message-container">
          <div class="fv-help-block">
            <ErrorMessage name="nombre" />
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-center my-5">
        <div class="text-center d-block col-lg-3 col-sm-12">
          <div class="w-100">
            <button
              type="submit"
              class="btn bg-blue rounded-pill text-white w-100"
            >
              Agregar
            </button>
          </div>
          <div class="w-100">
            <button type="button" class="btn text-blue w-100">Cancelar</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import sedes from "@/services/sedes.service";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { ErrorMessage, useField, useForm } from "vee-validate";
import * as Yup from "yup";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "crear-area",
  components: {
    ErrorMessage,
  },
  setup() {
    const ubicaciones = ref([]);
    const ubicacionId = ref("");
    const router = useRouter();
    const validationSchema = Yup.object().shape({
      nombre: Yup.string().required("Este campo es requerido"),
    });
    const {
      handleReset,
      handleSubmit,
      values: model,
    } = useForm({
      validationSchema,
    });
    useField("nombre", null, { initialValue: "" });
    const submit = handleSubmit(() => {
      sedes
        .crearAreas(ubicacionId.value, { nombre: model.nombre })
        .then((res) => {
          console.log(res);
          handleReset();
          router.go(-1);
          Swal.fire(
            "Mensaje",
            "La nueva area se ah creado con exito",
            "success"
          ).catch((err) => {
            console.log(err.response.data.message);
            Swal.fire("Error", err.response.data.message, "error");
          });
        });
    });
    const traerUbicaciones = () => {
      sedes.listarSedes().then((res) => {
        console.log(res);
        ubicaciones.value = res.data;
      });
    };
    onMounted(() => {
      setCurrentPageBreadcrumbs("Crear área", ["Pages", "Equipos"]);
      traerUbicaciones();
    });
    return { ubicaciones, submit, ubicacionId, model };
  },
});
</script>
<style scoped>
.border-blue {
  border-color: #27aae1 !important;
}
.text-gray {
  color: #999999;
}
.text-blue {
  color: #27aae1;
}
.bg-blue {
  background-color: #27aae1;
}
.bg-gray-input {
  background-color: #f4f8fb;
}
.listado-info {
  padding: 0;
  list-style: none;
}
.listado-info li label {
  font-size: 1.125rem;
}
.listado-info li p {
  font-size: 1.125rem;
  color: #999999;
}
</style>
